


















































































































import Vue from 'vue'
import Component from 'vue-class-component';
import Breadcrumb from '@/components/common/Breadcrumb.vue';
import Widget from '@/components/common/Widget.vue';
import DataTable from '@/components/table/DataTable.vue';

declare const $:any;

@Component({components: {Breadcrumb, Widget, DataTable}})
export default class EmployeeManagement extends Vue
{
  tableColums = [
    {
      label: "ID",
      prop: "id",
      sortable: true
    },
    {
      label: "Title",
      prop: "title",
      sortable: true
    },
    {
      label: "Vorname",
      prop: "firstName",
      sortable: true
    },
    {
      label: "Nachname",
      prop: "lastName",
      sortable: true
    },
    {
      label: "E-Mail Adresse",
      prop: "email",
      sortable: true
    },
    {
      label: "Access rights",
      prop: "accessRights",
      sortable: true
    },
  ]
  perPage = 25;
  orderby = "id";
  orderDirection = "asc";
  totalItems = 0;
  offsetPage = 0;
  tableRows = [
    {
      id: '111111',
      title: 'Mr.',
      firstName: 'David',
      lastName: 'Henkel',
      email: 'david@berlinbyt.es'
    }
  ]
  firstName = ''
  lastName = ''
  email = ''
  userId = ''
  title = ''

  add() {
    $('#add_modal').modal()
  }

  edit(event: any) {
    this.firstName = event.firstName
    this.lastName = event.lastName
    this.email = event.email
    this.userId = event.id
    this.title = event.title
    this.add()
  }

  deleteItem(event: any) {
    this.tableRows.splice(this.tableRows.findIndex(row => row.id === event.id), 1)
  }

  addUser() {
    if (this.userId) {
      this.tableRows.forEach(row => {
        if (row.id === this.userId) {
          row.firstName = this.firstName
          row.lastName = this.lastName
          row.email = this.email
          row.title = this.title
        }
      })
    } else {
      this.tableRows.push({
        id: '22222',
        title: this.title,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email
      })
    }
  }

  changeSortDirection(val: any) {
    this.orderDirection = val;
  }

  changeSortProperty(val: any) {
    this.orderby = val;
    this.orderDirection = 'asc';
  }

  changePerPage(val: any): void {
    if (val) {
      let offsetPage = val - 1;
      if (offsetPage !== this.offsetPage) {
        this.offsetPage = offsetPage;
      }
    }
  }
}
